import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import type { ReactNode } from 'react';
import { Hydrate } from 'react-query';
import { CookieRedirect, ErrorBoundary, ScrollRestoration } from '@vfit/consumer/components';
import { TrackingContextProvider, QueryProvider } from '@vfit/shared/data-access';
import {
  AppDynamics,
  DatadogWrapper,
  ModalErrorWrapper,
  ModalIframeWrapper,
  ModalLeadWrapper,
} from '@vfit/shared/components';
import { ReactQueryDevtools } from 'react-query/devtools';
import React, { useEffect, useState } from 'react';

import '../styles/css/globals.css';
import 'swiper/css';
import 'swiper/css/pagination';
import '../styles/carousel/offerCarousel.scss';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactNode) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const RELEASE_VERSION =
  process?.env?.['NEXT_PUBLIC_TAG_RELEASE_VERSION'] ??
  process?.env?.['NX_TAG_RELEASE_VERSION'] ??
  'not-available';

const DefaultApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const [loading, setLoading] = useState<boolean>(true);
  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    setLoading(false);
  }, []);

  const loadingComponent = () => <div />;

  return (
    <>
      <Head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <style>{'body {margin:0;padding:0;}'}</style>
        <meta name="release-version" content={`${RELEASE_VERSION}`} />
      </Head>
      <AppDynamics isConfig />
      <ErrorBoundary>
        <DatadogWrapper />
        <QueryProvider>
          <Hydrate state={pageProps.dehydratedState}>
            <TrackingContextProvider>
              <ScrollRestoration />
              <AppDynamics />
              <ModalLeadWrapper />
              <ModalErrorWrapper />
              <ModalIframeWrapper />
              <ReactQueryDevtools initialIsOpen={false} />
              {loading && loadingComponent()}
              {!loading && getLayout(<Component {...pageProps} />)}
              {!loading && <CookieRedirect />}
            </TrackingContextProvider>
          </Hydrate>
        </QueryProvider>
      </ErrorBoundary>
    </>
  );
};

export default DefaultApp;
